<template>
  <div class="resetPassword">
    <div class="reset content_area">
      <div class="tc title" v-if="step == 0">
        <i class="el-icon-back iconBack cursor" @click="back"></i
        >{{ $t("setNewPass") }}
      </div>
      <div class="content" v-if="step == 0">
        <el-form
          :label-position="'top'"
          label-width="80px"
          :model="resetForm"
          ref="resetForm"
          :rules="resetRules"
        >
          <el-form-item prop="password">
            <el-input
              v-model="resetForm.password"
              :placeholder="$t('wrongPasswordFormat')"
              type="password"
              size="medium"
              :show-password="true"
              nowritten
            ></el-input>
          </el-form-item>
          <el-form-item prop="passwordAgain">
            <el-input
              v-model="resetForm.passwordAgain"
              nowritten
              type="password"
              :placeholder="$t('pleaseAginEnterPass')"
              size="medium"
              :show-password="true"
            ></el-input>
          </el-form-item>
          <div class="button_block">
            <el-button class="phoneButton" @click="reset" :loading="loading">{{
              $t("confirm")
            }}</el-button>
          </div>
        </el-form>
      </div>
      <div v-if="step == 1">
        <div class="resetTips tc">
          <img :src="successImg" :alt="$t('siffa')" />
          <p>{{ $t("passwordResetSuccess1") }}</p>
          <router-link to="/sign">{{ $t("emailAladyTip2") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validatePassWord } from "~/baseUtils/validate";
import { SSstore } from "~/baseUtils/storage";
import { constName } from "~/baseUtils/constName";
import { _decodeString } from "~/basePlugins/base64";
export default {
  data() {
    return {
      resetForm: {
        password: "",
        passwordAgain: "",
      },
      step: 0,
      loading: false,
      successImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/success.png",
      resetRules: {
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validatePassWord(value)) {
              callback(new Error(this.$t("wrongPasswordFormat")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        passwordAgain: {
          validator: (rule, value, callback) => {
            if (this.resetForm.password == "") {
              callback();
            } else if (this.resetForm.password != "" && value == "") {
              callback(new Error(" "));
            } else if (
              this.resetForm.password != "" &&
              value !== this.resetForm.password
            ) {
              callback(new Error(this.$t("passwordMatch")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    if (this.$route.query.parameter) {
      let params;
      params = _decodeString(this.$route.query.parameter);
      if (params.user_id && params.email) {
        this.user_id = params.user_id;
        // try {
        //   let result = await this.$store.dispatch("API_user/user_Verify", {
        //     type: params.type,
        //     email: params.email,
        //     code: params.code,
        //     source: params.source,
        //     user_id: params.user_id
        //   });
        //   if (result.success) {
        //     return { user_id: params.user_id };
        //   }
        // } catch (e) {
        //   return { user_id: "" };
        // }
      }
    } else {
      this.user_id = SSstore.get(constName.siffa.resetPasswordUserId);
      if (!this.user_id) {
        this.$router.push("/forgetPassword");
      } else {
        SSstore.remove(constName.siffa.resetPasswordUserId);
      }
    }
  },
  methods: {
    reset() {
      this.$refs.resetForm.validate(async (vaild) => {
        if (vaild) {
          this.loading = true;
          let result = await this.$store.dispatch(
            "API_user/user_resetPassword",
            {
              user_id: this.user_id,
              password: this.resetForm.password,
            }
          );
          this.loading = false;
          if (result.success) {
            this.step = 1;
          } else {
            this.$seTip();
          }
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.resetPassword {
  min-height: 800px;
  background: #ffffff;
  .reset {
    width: 340px;
    margin: 0 auto;
    padding-top: 110px;
    .content {
      margin-top: 40px;
    }
    .title {
      font-size: 21px;
      font-weight: bold;
      .iconBack {
        border: 1px solid #7d8695;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}
.resetTips {
  p {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0px 10px;
  }
  a {
    color: #ff5155;
  }
}
.phoneButton {
  width: 100%;
  background: linear-gradient(90deg, #ff9966 0%, #ff5e62 100%);
  color: #fff;
  &:focus {
    border-color: #ff5155;
    background-color: #fee;
  }
}
</style>